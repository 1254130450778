<template>
  <b-col
    cols="12"
    md="8"
    lg="8"
    class="lines rides-stats-list"
  >
    <b-card>
      <div class="pl-2">
        <div class="ml-1">
          <span class="title">
            Rides
            <small
              v-if="mileageSeriesShown && roundNumber"
              class="ml-1 text-muted"
            >
              mileage is 10x smaller
            </small>
          </span>
          <!-- <div class="subtitle">
            {{ moment().format('YYYY-MM-DD') }}  <span class="mr-1"><span class="font-weight-bold">{{ scooterStatsTotalAmount }}</span> Ride(s)</span>
          </div> -->
        </div>
        <div class="col">
          <ul class="graph-buttons list-unstyled list-inline">
            <li>
              <div
                class="rides-btn rides"
                :class="showRides ? 'active' : ''"
                @click="toggleRides"
              >
                <span class="rides-icon" />
                <span class="rides-label">Rides</span>
              </div>
            </li>
            <li>
              <div
                class="rides-btn mileage-btn mileage"
                :class="showMileage ? 'active' : ''"
                @click="toggleMileage"
              >
                <span class="mileage-icon" />
                <span class="mileage-label">Mileage</span>
              </div>
            </li>
          </ul>

        </div>
      </div>
      <!-- will be removed after data corrected -->
      <card-statistic-line-chart-colors-liner
        :series="totalRidesList.series"
        :loaded="isLoaded"
        :default-type="defaultType"
        :default-interval="defaultInterval"
        :time-interval="false"
        :show-range-picker="false"
        :show-periods-picker="false"
        :show-rides="showRides"
        :show-mileage="showMileage"
        :colors="colors"
        :selected-dates="selectedDatesShown"
        chart-type="rides"
        :round-number="roundNumber"
        @mileage-series-shown-fn="mileageSeriesShownFN"
        @reload-chart="loadCharts"
      />
    </b-card>
    <b-row>
      <RidesGeneralData :general-data="generalData" />
    </b-row>
  </b-col>
</template>
<script>
import CardStatisticLineChartColorsLiner from '@/views/components/statistics/CardStatisticLineChartColorsLiner.vue'
import RidesGeneralData from '@/views/rides/components/rides-list/RidesGeneralData.vue'
import moment from 'moment'

export default {
  components: {
    CardStatisticLineChartColorsLiner,
    RidesGeneralData,
  },
  props: {
    totalRides: {
      type: Object,
      default() {
        return Object
      },
    },
    // statisticsItems: {
    //   type: Array,
    //   default() {
    //     return []
    //   },
    // },
    scooterStatsTotal: {
      type: Number,
      default() {
        return 0
      },
    },
    generalData: {
      type: Object,
      default() {
        return null
      },
    },
    selectedDates: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      mileageSeriesShown: true, // remove after data corrected
      moment,
      isLoaded: true,
      totalRidesList: this.totalRides,
      statisticsItemsList: this.statisticsItems,
      scooterStatsTotalAmount: this.scooterStatsTotal,
      ridesStatsTotalAmount: 0,
      mileageStatsTotalAmount: 0,
      defaultType: 'all-time',
      defaultInterval: 'monthly',
      showRides: true,
      showMileage: true,
      colors: ['#3427C8', '#FF9F43'],
      roundNumber: null, // remove after data corrected
      selectedDatesShown: null,
    }
  },
  watch: {
    selectedDates(newSelectedDates) {
      this.selectedDatesShown = newSelectedDates
    },
  },
  mounted() {
    this.loadCharts()
  },
  created() {
    // listen to event from app.vue page
    this.$eventHub.$on('selectedPageRangeChanged', this.refreshChart)
  },
  beforeDestroy() {
    // ignore to event from app.vue page
    this.$eventHub.$off('selectedPageRangeChanged')
  },
  methods: {
    refreshChart(showLoader = true,dates = null, interval = null, period = null, applyDates = false) {
      this.defaultType = period;
      this.defaultInterval = interval
      this.selectedDatesShown = dates
      this.loadCharts(showLoader, this.defaultType, this.defaultInterval, this.selectedDatesShown, applyDates)
    },
    // remove after data corrected
    mileageSeriesShownFN(value) {
      this.mileageSeriesShown = value
    },
    toggleRides() {
      this.showRides = !this.showRides
    },
    toggleMileage() {
      this.showMileage = !this.showMileage
    },
    loadCharts(showLoader = true, type = null, interval = null, dates = null,applyDates = false) {

      if(showLoader) {
        this.statsLoading = true
        this.isLoaded = false
      }
      // the problem was when we select date we still need to load charts whichs not contain dates just intervals and periods 
      this.defaultType = type ?? this.defaultType
      this.defaultInterval = interval ?? this.defaultInterval
      const postData = {}
      if (dates && applyDates) {
        if (dates.start_date) {
          postData.start_date = dates.start_date
        }
        if (dates.end_date) {
          postData.end_date = dates.end_date
        }
      }
      postData.type = 'distance_mileage'
      postData.interval = this.defaultInterval ? this.defaultInterval : 'monthly'

      this.$http.post(this.$resources.rides.calculations.charts, postData ).then(response => {
        const { data: { status, data: { mileage, rides } } } = response
        if (status === 200) {
          this.totalRidesList = {
            series: [{
              data: rides.stats.map(stat => ({ x: stat.label, y: stat.count })),
              name: 'Rides',
              color: this.colors[0],
            },
            {
              data: mileage.stats.map(stat => ({ x: stat.label, y: this.roundNumber ? (stat.count / this.roundNumber) : stat.count })),
              name: 'Mileage',
              color: this.colors[1],
            }],
          }
          this.ridesStatsTotalAmount = rides.total
          this.mileageStatsTotalAmount = mileage.total
          // this.showMileage = true
          // this.showRides = true
        }
        if(showLoader) {
          this.isLoaded = true
        }

      })
    },
  },
}
</script>

<style lang="scss" scoped>
.rides-stats-list {

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card {
    .card-body{
      min-height: 640px;
    }
    margin: 0px;
  }
}
</style>
