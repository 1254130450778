<template>
  <b-col
    cols="12"
  >
    <b-card title="General">
      <div class="data-wrapper">
       

        <div
          class="general-item"
        >
          <div
            class="general-data-image"
          >
            <img
              :src="require(`@/assets/images/icons/ride/trip-length.png`)"
            >
          </div>
          <div class="data">
            <div class="title">
              Average trip length
            </div>
            <div class="data">
              {{ generalData.avg_trip ? parseInt(generalData.avg_trip).toFixed(2) : 0  }}km
            </div>
          </div>
        </div>


        <div
          class="general-item"
        >
          <div
            class="general-data-image"
          >
            <img
              :src="require(`@/assets/images/icons/ride/mileage.png`)"
            >
          </div>
          <div class="data">
            <div class="title">
              Mileage
            </div>
            <div class="data">
              {{ generalData.mileage ? parseInt(generalData.mileage).toLocaleString("en-US") : 0  }}km
            </div>
          </div>
        </div>



        <div
          class="general-item"
        >
          <div
            class="general-data-image"
          >
            <img
              :src="require(`@/assets/images/icons/ride/trips-per-scooters.png`)"
            >
          </div>
          <div class="data">
            <div class="title">
              Rides / Scooter
            </div>
            <div class="data">
              {{ generalData.trips_per_scooter }}
            </div>
          </div>
        </div>


        <div
          class="general-item"
        >
          <div
            class="general-data-image"
          >
            <img
              :src="require(`@/assets/images/icons/ride/android.png`)"
            >
          </div>
          <div class="data">
            <div class="title">
              Rides / User
            </div>
            <div class="data">
              {{ generalData.android }}
            </div>
          </div>
        </div>



        <div
          class="general-item"
        >
          <div
            class="general-data-image"
          >
            <img
              :src="require(`@/assets/images/icons/ride/apple.png`)"
            >
          </div>
          <div class="data">
            <div class="title">
              Rides / User
            </div>
            <div class="data">
              {{ generalData.ios }}
            </div>
          </div>
        </div>




      </div>
    </b-card>
  </b-col>
</template>
<script>

export default {
  props: {
    generalData: {
      type: Object,
      default() {
        return null
      },
    },
  },
}
</script>
<style lang="scss" scoped>
 .data-wrapper {
    display: inline;
    .general-item {
      display: flex;
      width: 20%;
      margin-bottom: 15px;
      float: left;
      .general-data-image {
        width: 40px;
        height: 40px;
        background: #F5F3F2;
        border: 1px solid rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
      }
      .data {
        font-family: 'Montserrat';
        font-style: normal;
        .title {
          font-weight: 600;
          font-size: 14px;
          color: #9A8781;
        }
        .data {
          font-weight: 600;
          font-size: 18px;
          line-height: 133%;
          color: #151210;
        }
      }

    }
 }

 @media screen and (max-width: 1440px) {
  .data-wrapper {
    .general-item{
      width: 25%;
    }
  }
}

@media screen and (max-width: 1350px) {
  .data-wrapper {
    .general-item{
      width: 30%;
    }
  }
}

@media screen and (max-width: 1140px) {
  .data-wrapper {
    .general-item{
      width: 50%;
    }
  }
}

@media screen and (max-width: 550px) {
  .data-wrapper {
    .general-item{
      width: 100%;
    }
  }
}
</style>
