<template>
  <b-col
    cols="12"
    class="pie"
    md="4"
    lg="4"
  >
    <b-card>
      <span class="title">
        Rides
      </span>
      <div>
        <card-statistic-circle-chart-colors
          :key="`key-${moment()}`"
          :series="scooterStats.series"
          :labels="scooterStats.labels"
          :total-scooters="scooterStats.totalScooters"
          :colors="colors"
        />
      </div>
      <div class="scooters-div">
        <b-row
          v-for="(scooter,index) in scooterStatsList"
          :key="index"
          class="no-margin"
        >
          <b-col class="ride-line">
            <span
              class="circle-colored-icon"
              :style="'border-color:'+scootersColors[scooter.scooter].color"
            />
            <span class="ride-scooter-type">{{ scooter.scooter }}</span>
            <span class="percentage float-right">{{ scooter.percentage }}%</span>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-col>
</template>
<script>
import CardStatisticCircleChartColors from '@/views/components/statistics/CardStatisticCircleChartColors.vue'
import moment from 'moment'

export default {
  components: {
    CardStatisticCircleChartColors,
  },
  data() {
    return {
      moment
    }
  },
  props: {
    selectedDates: {
      type: Object,
      default() {
        return null
      },
    },
    scooterStats: {
      type: Object,
      default() {
        return Object
      },
    },
    scooterStatsList: {
      type: Array,
      default() {
        return []
      },
    },
    scootersColors: {
      type: Object,
      default() {
        return Object
      },
    },
    colors: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>
