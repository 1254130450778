<template>
  <div class="rides-list">
    <b-row class="rides-list-charts">
      <template v-if="statsLoading && scooterStatsLoading">
        <b-col class="rides-list-charts-loader">
          <widget-loader />
        </b-col>
      </template>
      <template v-else>
        <rides-scooter-statistics
          :scooter-stats="scooterStats"
          :scooter-stats-list="scooterStatsList"
          :scooters-colors="scootersColors"
          :colors="colors"
          :selected-dates="selectedDatesShown"
        />
        <!-- we have to unify this component later to not repeat ourselves for (cutomers, rides, scooters)-->
        <rides-stats-list
          :total-rides="totalRides"
          :statistics-items="statisticsItems"
          :general-data="generalData"
          :selected-dates="selectedDatesShown"
        />
      </template>
    </b-row>
    <b-card class="rides-list-table">
      <b-col>
        <h4>Rides List</h4>
        <new-table
          :export-file-name="exportFileName"
          :data-source="ridesStore"
          table-name="rides-list"
          :columns="columns"
          router-link="view-ride"
        />
      </b-col>
    </b-card>
  </div>
</template>

<script>
import NewTable from '@/views/new-table-ssr.vue'
import Moment from 'moment'
import {
  BCard, VBTooltip, BCol, BRow,
} from 'bootstrap-vue'
import CustomStore from 'devextreme/data/custom_store'
import RidesStatsList from '@/views/rides/components/rides-list/RidesStatsList.vue'
import RidesScooterStatistics from '@/views/rides/components/rides-list/RidesScooterStatistics.vue'
import WidgetLoader from '@/views/components/WidgetLoader.vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    NewTable,
    RidesStatsList,
    RidesScooterStatistics,
    WidgetLoader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      generalData: null,
      selectedDates: null,
      selectedDatesShown: null,
      ridesStore: null,
      tableFilterData: [],
      moment: Moment,
      default_scooter_color: '#cccccc',
      exportFileName: `RidesList_${Moment(new Date()).format('YYYY-MM-DD')}`,
      loading: false,
      scooterStatsLoading: true,
      items: [],
      scooterStats: {},
      scooterStatsTotal: 0,
      scooterStatsList: [],
      statisticsItems: [],
      statsByMonth: null,
      statsLoading: true,
      oldFilter: '',
      current_date: Moment(new Date()).format('YYYY-MM-DD'),
      scootersColors: {},
      scooterTypes: [],
      colors: [],
      ridesStats: {
        series: [45, 15, 8, 4, 2, 2, 2],
        labels: ['Phantom', 'City', 'Ghost', 'AirPro', 'One', 'Mini'],
      },
      totalRides: {
        series: [{
          name: 'Total Rides',
          data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5],
        }],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      fields: [
        { key: 'start_time', label: 'Start Time', sortable: true },
        { key: 'end_time', label: 'End Time', sortable: true },
        { key: 'scooter_serial', label: 'Scooter Serial', sortable: true },
        { key: 'customer_email', label: 'Customer', sortable: true },
        { key: 'distance', label: 'Distance', sortable: true },
        { key: 'controller_temp', label: 'Controller Temperature', sortable: true },
        { key: 'device', label: 'OS', sortable: true },
        { key: 'view_btn', label: 'View' },
      ],
      columns: [
        {
          label: 'Start time',
          data: 'start_time',
          type: 'date',
          format: 'dd-MM-yyyy hh:mm a',
          sort: 'desc',
          calculateCellValue(data) {
            return Moment.utc(data.start_time).local().format('yyyy-MM-D hh:mm:ss a')
          },
        },
        {
          label: 'End Time',
          data: 'end_time',
          type: 'date',
          format: 'dd-MM-yyyy hh:mm a',
          sort: 'desc',
          calculateCellValue(data) {
            return Moment.utc(data.end_time).local().format('yyyy-MM-D hh:mm:ss a')
          },
        },
        {
          label: 'Scooter Serial',
          data: 'scooter_serial',
        },
        {
          label: 'Customer',
          data: 'email',
        },
        {
          label: 'Distance',
          data: 'distance',
        },
        {
          label: 'Country',
          data: 'country',
        },
        {
          label: 'Controller Temperature',
          data: 'max_controller_temperature',
        },
        {
          label: 'Platform',
          data: 'platform',
          custom_template: {
            template_name: 'rides.device_template',
            filterValue: 'IOS',
            options: {
              android: {
                icon: 'android-icon',
                tooltip: 'Android',
              },
              ios: {
                icon: 'ios-icon',
                tooltip: 'IOS',
              },
              react: {
                icon: 'react-icon',
                tooltip: 'React',
              },
              1: {
                icon: 'unknown-icon',
                tooltip: 'Unknown',
              },
            },
          },
          custom_dropdown: {
            enabled: true,
            display: 'display',
            value: 'value',
            options: [
              {
                display: 'Android',
                value: 'Android',
              },
              {
                display: 'IOS',
                value: 'IOS',
              },
              {
                display: 'React',
                value: 'React',
              },
            ],
            calculateCellValue(data) {
              return data.device === undefined ? 'react' : data.device.toLowerCase()
            },
          },
        },
      ],
      page_name: 'rides-list'
    }
  },
  created() {
    // listen to event from app.vue page
    this.$eventHub.$on('selectedPageRangeChanged', this.refreshPage)

  },
  beforeDestroy() {
    // ignore to event from app.vue page
    this.$eventHub.$off('selectedPageRangeChanged', this.refreshPage)
  },
  mounted() {
    this.$store.commit('menuConfig/SET_PAGE_TITLE', 'Rides')
    this.mountFilerData()
    this.loadStats()
    this.loadRides()
  },
  methods: {
    refreshPage(showLoader = true ,dates = null) {
      this.selectedDatesShown = dates
      this.loadStats(showLoader, dates)
      this.loadRides(dates)
    },
    loadRides(dates = null) {
      this.ridesStore = new CustomStore({
        key: 'id',
        load: loadOptions => {
          if (loadOptions.filter !== undefined && loadOptions.filter.length > 0) {
            this.setFilterData(this.page_name, loadOptions)
          } else {
            this.setFilterData(this.page_name, null)
          }


          if (loadOptions.sort !== null && loadOptions.sort.length > 0) {
            this.setSortData(this.page_name, loadOptions.sort)
          } else {
            this.setSortData(this.page_name, null)
          }




          let postData = {
            search_filters: loadOptions,
          }

          postData = this.$getPostData(postData, dates)

          return this.$http.post(this.$resources.rides.index, postData)
            .then(data => ({
              data: data.data.data.rows,
              totalCount: data.data.data.pagination.total,
            }))
            .catch(error => { console.log(error) })
        },
      })
    },
    mountFilerData() {
      this.columns = this.getFilterData(this.page_name, this.columns)
    },
    resetScooterColorList() {
      this.scooterStatsList = []
      this.scooterStats = {
        series: [],
        labels: [],
      }
      this.colors = []
      this.scootersColors = {}
      this.scooterTypes = {}
    },
    loadStats(showLoader = true, dates = null) {
      if(showLoader)
      {
        this.scooterStatsLoading = (dates && dates.length > 0) ?? true
      } 
      const postData = this.$getPostData({}, dates)
      postData.type = 'scooter_types'
      
      this.$http.post(this.$resources.rides.calculations.charts, postData).then(response => {
        this.resetScooterColorList()
        const { data: { status, data } } = response
        if (status === 200) {
          this.scooterStats = {
            series: Object.values(data.series),
            labels: Object.values(data.colors.map(color => color.type)),
          }

          
          this.scooterStatsTotal = this.scooterStats.series.reduce((a, b) => a + b)

          this.scooterStats.labels.forEach((scooter, index) => {
            this.scooterStatsList.push({
              scooter,
              count: this.scooterStats.series[index] ? this.scooterStats.series[index] : 0,
              percentage: this.scooterStats.series[index] ? ((this.scooterStats.series[index] / this.scooterStatsTotal) * 100).toFixed(2) : 0,
            })
          })

          this.scooterStats.labels.forEach((label,index) => {
            const scooter  = data.colors.filter(scooter => scooter.type === label)[0]

            if (typeof scooter?.color !== undefined) {
              this.colors.push(scooter.color)
              this.scootersColors[label] = {color: scooter.color}
            } else {
              this.colors.push(this.default_scooter_color)
              this.scootersColors[label].color = this.default_scooter_color
            }
          })

        }
      }).then(() => {      

        this.$http.post(this.$resources.rides.calculations.stats, postData).then(response => {
          const { data: { status, data } } = response
          if (status === 200) {
            // Object.keys(data).forEach(key => {
            //   this.generalData[key].data = (this.generalData[key].data && data[key]) ? data[key] : 0
            // })
            this.generalData = data
            if(showLoader) {
              this.statsLoading = false
              this.scooterStatsLoading = false
            } 
            this.$store.commit('menuConfig/SET_TIME_PICKER_SELECTOR_LOADER', false)
          }
        })

      })
    },
  },
}
</script>
<style lang="scss">
  @import '@/views/rides/scss/ridesList.scss';
</style>
